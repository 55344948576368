<template>
  <div>
    <div class="box">
      <div class="newhead">
        <div class="headbox">
          <div class="headboxnav">
            <div class="headboxnavtit">总金额</div>
          </div>
          <div class="headboxmain">
            <div class="headboxleft">
              <span class="priceall">¥{{ companyinfo.total1 }}</span>
              <el-popover placement="top-start" width="auto" height="auto" trigger="hover">
              <div class="tishi">查看明细</div>
              <template #reference>   
              <div class="pricetit" @click="gd(2)">
                <i class="dian3"></i>
                现金订单：
                <span class="pricenum">¥{{ companyinfo.xmoney }}</span>
              </div>
              </template>
              </el-popover>
              <el-popover placement="top-start" width="auto" height="auto" trigger="hover">
              <div class="tishi">查看明细</div>
              <template #reference>  
              <div class="pricetit" @click="gd(1)">
                <i class="dian2"></i>
                挂账订单：
                <span class="pricenum">¥{{ companyinfo.gmoney }}</span>
              </div>
              </template>
              </el-popover>
              <el-popover placement="top-start" width="auto" height="auto" trigger="hover">
              <div class="tishi">查看明细</div>
              <template #reference>  
              <div class="pricetit" @click="gd(3)">
                <i class="dian"></i>
                流转订单：
                <span class="pricenum">¥{{ companyinfo.pmoney }}</span>
              </div>
              </template>
              </el-popover>
            </div>
            <div class="headboxright">
              <div
                id="main"
                style="width: 100px; height: 100px"
                ref="chart"
              ></div>
            </div>
          </div>
        </div>

        <div class="headbox">
          <div class="headboxnav">
            <div class="headboxnavtit">订单量</div>
          </div>
          <div class="headboxmain">
            <div class="headboxleft">
              <span class="priceall">{{ companyinfo.totalCount }}</span>
               <el-popover placement="top-start" width="auto" height="auto" trigger="hover">
              <div class="tishi">查看明细</div>
              <template #reference>             
              <div class="pricetit" @click="gd(2)">
                <i class="dian3"></i>
                现金单量：
                <span class="pricenum">{{ companyinfo.xnum }}</span>
              </div>
                               </template>
                </el-popover>
              <el-popover placement="top-start" width="auto" height="auto" trigger="hover">
              <div class="tishi">查看明细</div>
              <template #reference>
              <div class="pricetit" @click="gd(1)">
                <i class="dian2"></i>
                挂账单量：
                <span class="pricenum">{{ companyinfo.gnum }}</span>
              </div>
                               </template>
                </el-popover>
              <el-popover placement="top-start" width="auto" height="auto" trigger="hover">
              <div class="tishi">查看明细</div>
              <template #reference>
              <div class="pricetit" @click="gd(3)">
                <i class="dian"></i>
                流转单量：
                <span class="pricenum">{{ companyinfo.pnum }}</span>
              </div>
              </template>
              </el-popover>
            </div>
            <div class="headboxright">
              <div
                id="main2"
                style="width: 100px; height: 100px"
                ref="chart2"
              ></div>
            </div>
          </div>
        </div>

        <div class="headbox">
          <div class="headboxnav">
            <div class="headboxnavtit">应收金额</div>
            <div class="headboxnavtit" v-if="hasOp">应付金额</div>
          </div>
          <div class="headboxmain">
            <div class="headboxleft">
              <span class="priceall yingshou">¥{{ companyinfo.total1 }}</span>
              <div class="tiao"></div>
              <div class="yingtit">
                <div class="pricetit">
                  差异：
                  <i class="chatit" v-if="companyinfo.dmoney > 0">¥{{ companyinfo.dmoney }}</i>
                  <i class="chatitred" v-if="companyinfo.dmoney < 0">¥{{ companyinfo.dmoney }}</i>
                  <i v-if="companyinfo.dmoney == 0">¥{{ companyinfo.dmoney }}</i>
                </div>
                <div class="pricetit">
                  单毛利：
                  <i class="newbalancenum">¥{{ companyinfo.mmoney }}</i>
                </div>
              </div>
            </div>
            <div class="headboxright yingfu" v-if="!hasOp">
              <span class="priceall">&nbsp;</span>
              <div class="tiao2"></div>
              <div class="yingtit">应付金额</div>
              <span class="priceall">¥{{companyinfo.total2}}</span>
            </div>
            <div class="headboxright yingfu1" v-if="hasOp">
              <span class="priceall">¥{{ companyinfo.total2 }}</span>
              <div class="tiao5"><div class="tiao3"></div><div class="tiao4"></div></div>
              <div class="yingtit">外协金额</div>
              <span class="priceall">¥{{companyinfo.opmoney}}</span>
            </div>
          </div>
        </div>

        <div class="headbox4">
          <div class="headboxnav headall">
            <div class="headboxnavtit">可提现总金额</div>
            <div class="headboxbtn">
              <button class="tixian" @click="ti" v-if="getismain && !getlook && !isSale">提现</button>
              <!--<button class="Recharge" @click="sendMesFroIframe">充值</button>-->
            </div>
          </div>
          <span class="priceall yingall">¥{{companyinfo.account}}</span>
          <div class="newbalance">
            <div class="newbalanceleft">
              平台提现余额：
              <span class="newbalancenum">¥{{companyinfo.leftmoney}}</span>
            </div>
            <div class="newbalanceright">
              平台提现金额：
              <span class="newbalancenum">¥{{companyinfo.aftermoney}}</span>
            </div>
          </div>
          <div class="newbalance" v-if="isShow">
            <!--<div class="newbalanceleft">
              微信商户余额：
              <span class="newbalancenum">¥500.00</span>
            </div>-->
            <div class="newbalanceright">
              微信商户金额：
              <span class="newbalancenum">¥{{companyinfo.wxmoney}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-box">
        <div class="option">
          <div class="mt-4">
            <div class="headboxbtn">
              <button class="all">全部</button>
            </div>
            <el-checkbox-group v-model="group" size="large">
              <el-checkbox-button
                v-for="city in cities"
                :key="city.id"
                :label="city.id"
              >
                {{ city.name }}
              </el-checkbox-button>
            </el-checkbox-group>    
          </div>

        </div>
        <div class="option" style="margin-top:10px;">
          <div class="mt-4">
            <!-- 技师 -->
            <div class="jishi" v-if="getismain">
              <el-select
                filterable
                class="input-unit"
                v-model="tech"
                placeholder="技师"
              >
                <el-option
                  v-for="item in techlist"
                  :key="item.uid"
                  :label="item.nickname"
                  :value="item.uid"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 调度 -->
            <div class="zhong" v-if="getismain">
              <el-select
                filterable
                class="input-unit"
                v-model="kefu"
                placeholder="调度"
              >
                <el-option
                  v-for="item in kefulist"
                  :key="item.uid"
                  :label="item.nickname"
                  :value="item.uid"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 业务员 -->
            <div class="zhong" v-if="getismain && !isSale">
              <el-select
                filterable
                class="input-unit"
                v-model="saleman"
                placeholder="业务员"
              >
                <el-option
                  v-for="item in saleslist"
                  :key="item.uid"
                  :label="item.nickname"
                  :value="item.uid"
                >
                </el-option>
              </el-select>
            </div>
            <div class="zhong">
            <el-select
              filterable
              v-model="srccid"
              placeholder="合作单位"
              v-if="isOp"
            >
              <el-option
                v-for="item in heZuoOptions"
                :key="item.cid"
                :value="item.cid"
                :label="item.name"
              >
                {{ item.name }}
              </el-option>
            </el-select>
            </div>
            <div class="jishi">
            <el-select v-model="chargetype" placeholder="收费类型">
              <el-option label="全部" value="0"></el-option>
              <el-option label="挂账" value="1"></el-option>
              <el-option label="现金" value="2"></el-option>
              <el-option label="平台单" value="3" v-if="getismain"></el-option>
            </el-select>
            </div>
            <div class="jishi">
            <el-select v-model="settletype" placeholder="入账">
              <el-option label="全部"   value=0></el-option>
              <el-option label="未入账" value=1></el-option>
              <el-option label="已入账" value=2></el-option>
            </el-select>
            </div>
            <div class="jishi">
            <el-select v-model="hesuantype" placeholder="核销">
              <el-option label="全部" value=0></el-option>
              <el-option label="未核销" value=1></el-option>
              <el-option label="已核销" value=2></el-option>
            </el-select>
            </div>
          </div>
        </div>
        <div class="import">
          <div class="importleft">
          <!-- 创建时间 -->
            <el-form ref="form">
              <el-form-item label="创建时间">
                <el-col :span="11">
                  <el-date-picker
                    v-model="value3"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>
            <!-- 完成时间 -->
            <el-form ref="form">
              <el-form-item label="完成时间">
                <el-col :span="11">
                  <el-date-picker
                    v-model="value2"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>
            <!-- 时间 -->
            <el-form ref="form">
              <el-form-item label="救援时间">
                <el-col :span="11">
                  <el-date-picker
                    v-model="value1"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="起始时间"
                    end-placeholder="结束时间"
                  >
                  </el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>
          </div>

          <div class="seek">
            <el-select
              filterable
              :style="unit"
              v-model="keytype"
              placeholder="下拉选择"
            >
              <el-option
                v-for="item in sousuo"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
            <input
              class="sekint"
              type="text"
              @keyup.enter="lookfor"
              v-model="keyword"
              placeholder="输入关键字"
            />
            <button class="chaxun" @click="lookfor">查询</button>

            <div class="export" @click="export1" v-if="(canExport && !getlook) || isSale">
              <p class="export-p">导出</p>
            </div>
            <div class="export" @click="renew">
              <p class="export-p">重置</p>
            </div>
            <div class="export" v-if="!getlook && !isSale">
              <p class="export-p" @click="pizhang">批量入账</p>
            </div>
            <div class="export" v-if=" !getlook  && !isSale">
              <p class="export-p" @click="piliang">批量核销</p>
            </div>
            <div class="export" v-if="!getlook && !isSale">
              <p class="export-p" @click="pizhangall">全部入账</p>
            </div>
            <div class="export" v-if=" !getlook  && !isSale">
              <p class="export-p" @click="piliangall">全部核销</p>
            </div>
            <button class="export" disabled style="background: #bcbcbc; border: 0;color:#fff; cursor:not-allowed;" v-if="isSale">批量入账</button>
            <button class="export" disabled style="background: #bcbcbc; border: 0;color:#fff; cursor:not-allowed;" v-if="isSale">批量核销</button>
 
          </div>
        </div>
      </div>

      

      <div class="main-box">
        <div class="wealthlist">
          <el-table
            :data="list"
            stripe
            height="98%"
            width="100%"
            row-key="id"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
            />
            <el-table-column label="编号" width="200" :reserve-selection="true">
              <template #default="scope">
                <span class="xiangqing" @click="dd(scope.row.id)"><img class="li-img" :src="scope.row.svg2" alt="" />{{
                  
                  scope.row.id
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="rescueTime"
              label="救援时间"
              width="150"
              :reserve-selection="true"
            />
            <el-table-column
              label="合作单位"
              width="200"
              :reserve-selection="true"
            >
              <template #default="scope">
                {{ scope.row.companyname }}
                <br />
                <!--<i>{{ scope.row.rescuetypetxt }}</i>-->
              </template>
            </el-table-column>
            <el-table-column label="应收" width="80" :reserve-selection="true">
              <template #default="scope">
                {{ scope.row.yingshou }}
              </template>
            </el-table-column>
             <el-table-column label="实收" width="80" :reserve-selection="true">
              <template #default="scope">
                {{ scope.row.shishou }}
              </template>
            </el-table-column>
            <el-table-column label="差异" width="80" :reserve-selection="true">
              <template #default="scope">
                <span style="color:red;" v-if="scope.row.dif < 0">{{ scope.row.dif }}</span>
                <span v-if="scope.row.dif == 0">{{ scope.row.dif }}</span>
                <span style="color:#29cc8c;" v-if="scope.row.dif > 0">{{ scope.row.dif }}</span>
                
              </template>
            </el-table-column>
            <el-table-column label="应付" width="80" :reserve-selection="true">
              <template #default="scope">
                {{ scope.row.yingfu }}
              </template>
            </el-table-column>
            <el-table-column label="挂账" width="80" :reserve-selection="true">
              <template #default="scope">
                {{ scope.row.guazhang }}
              </template>
            </el-table-column>
            <el-table-column label="收现" width="80" :reserve-selection="true">
              <template #default="scope">
                {{ scope.row.car_pay }}
              </template>
            </el-table-column>
            <el-table-column label="外协" width="80" :reserve-selection="true" v-if="hasOp">
              <template #default="scope">
                {{ scope.row.op_price }}
              </template>
            </el-table-column>
            <el-table-column
              label="提成"
              width="80"
              v-if="getismain"
              :reserve-selection="true"
            >
              <template #default="scope">
                {{ scope.row.take_commission_money }}
              </template>
            </el-table-column>
            <el-table-column label="毛利" width="80" :reserve-selection="true">
              <template #default="scope">
                <span style="color:red;" v-if="scope.row.ml < 0">{{ scope.row.ml }}</span>
                <span v-if="scope.row.ml == 0">{{ scope.row.ml }}</span>
                <span style="color:#29cc8c;" v-if="scope.row.ml > 0">{{ scope.row.ml }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="车主信息"
              width="180"
              :reserve-selection="true"
            >
              <template #default="scope">
                <span v-html="scope.row.ownertxt"></span>
              </template>
            </el-table-column>
            <el-table-column label="位置" width="300" :reserve-selection="true">
              <template #default="scope">
                {{ scope.row.address }}
              </template>
            </el-table-column>
            <el-table-column
              label="接单人信息"
              width="200"
              :reserve-selection="true"
            >
              <template #default="scope">
                <span v-html="scope.row.take_user"></span>
              </template>
            </el-table-column>
            <el-table-column
              label="接单人车牌"
              width="100"
              :reserve-selection="true"
            >
              <template #default="scope">
                {{ scope.row.take_car_number }}
              </template>
            </el-table-column>
            <el-table-column
              label="自有单号"
              width="250"
              v-if="isShow"
              :reserve-selection="true"
            >
              <template #default="scope">
                {{ scope.row.iflow }}
                <br />
                {{ scope.row.iflow_money }}
              </template>
            </el-table-column>
            <el-table-column
              label="平台单号"
              width="250"
              v-if="isShow"
              :reserve-selection="true"
            >
              <template #default="scope">
                {{ scope.row.flow }}
                <br />
                {{ scope.row.flow_money }}
              </template>
            </el-table-column>

            <el-table-column
              fixed="right"
              label="操作"
              v-if="getismain"
              :reserve-selection="true"
            >
              <template #default="scope">
                <el-button
                  link
                  type="primary"
                  size="small"
                  @click="settle(scope.row)"
                  v-if="scope.row.issettle == 0 && !getlook && !isSale"
                  >入账</el-button
                >
                <el-button
                  link
                  type="primary"
                  size="small"
                  style="background: #bcbcbc; border: 0"
                  v-if="scope.row.issettle == 1 && !getlook  && !isSale"
                  >已入账</el-button
                >
                <el-button
                  link
                  type="primary"
                  size="small"
                  style="background: #bcbcbc; border: 0"
                  v-if="getlook || isSale"
                  disabled
                  >{{scope.row.issettle == 1?'已入账':'入账'}}</el-button
                >
                <el-button
                  link
                  type="primary"
                  size="small"
                  style="background: #bcbcbc; border: 0"
                  disabled
                  v-if="getlook || isSale"
                  >{{scope.row.ishesuan == 1?'已核销':'核销'}}</el-button
                >
                <el-button
                  link
                  type="primary"
                  size="small"
                  v-if="scope.row.ishesuan == 0 && !getlook && !isSale"
                  @click="accounting(scope.row.id)"
                  >核销</el-button
                >
                <el-button
                  link
                  type="primary"
                  size="small"
                  style="background: #bcbcbc; border: 0"
                  v-if="scope.row.ishesuan == 1 && !getlook  && !isSale"
                  >已核销</el-button
                >
                <el-button
                  link
                  type="primary"
                  size="small"
                  v-if="scope.row.canEdit && !getlook  && !isSale"
                  @click="xiu(scope.row.id)"
                  >修改</el-button
                >
                <el-button
                  link
                  type="primary"
                  size="small"
                  disabled
                  style="background: #bcbcbc; border: 0"
                  v-if="!scope.row.canEdit || getlook || isSale"
                  >修改</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="allNum">总共{{ this.total }}条</div>
      </div>
    </div>
    <part :islook="look" :id="detailid"></part>
    <deposit :isTi="xian" @tixians="sxx"></deposit>
    <modifypart
      v-if="xiupart"
      :id="detailid"
      @closepart="closepart"
    ></modifypart>
    <entry v-if="zhangyin" :entryid="entryid" :yingshou="yingshou" @guanentyr1="guanentyr1" @guanentyr2="guanentyr2"></entry>
    <pientry
      v-if="pizhangyin"
      :pientryids="pientryids"
      @guanpientyr="guanpientyr"
      @guanpientyr2="guanpientyr2"
    ></pientry>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import affairs from "../components/affairs.vue";
import * as api from "../api/order";
import Part from "../components/part.vue";
import entry from "../components/entry.vue";
import pientry from "../components/pientry.vue";
import Deposit from "../components/deposit.vue";
import * as index from "../api/index";
import { ElMessageBox } from "element-plus";
import Modifypart from "../components/modifypart.vue";

const cities = [
  {
    id: 1,
    name: "非事故拖车",
  },
  {
    id: 2,
    name: "事故拖车",
  },
  {
    id: 3,
    name: "搭电",
  },
  {
    id: 4,
    name: "换胎",
  },
  {
    id: 5,
    name: "送水",
  },
  {
    id: 6,
    name: "送油",
  },
  {
    id: 7,
    name: "充气",
  },
  {
    id: 8,
    name: "快修",
  },
  {
    id: 9,
    name: "开锁",
  },
  {
    id: 10,
    name: "吊车",
  },
  {
    id: 11,
    name: "困境",
  },
  {
    id: 12,
    name: "地库",
  },
];
const value1 = ref("");
const echarts = require("echarts");

export default {
  components: { affairs, Part, Deposit, Modifypart, entry, pientry },
  data() {
    return {
      zhangyin: false,
      entryid: "",
      pizhangyin: false,
      pientryid: "",
      srccid: "",
      group: [],
      keytype: "",
      keyword: "",
      jishi: "",
      diaodu: "",
      yewu: "",
      value1: "",
      value2: "",
      value3: "",
      cities,
      heZuoOptions: [],
      list: [],
      chargetype: "",
      total: "",
      look: 0,
      xian: 0,
      detailid: "",
      ismain: "",
      companyinfo: {
        account: 0,
        total1: 0,
        total2: 0,
        total3: 0,
        total4: 0,
        total5: 0,
        opmoney: 0,
      },
      datas: {
        page: 1,
        perpage: 15,
        srccid: "",
        chargeType: "",
        createTimeBegin: "",
        createTimeEnd: "",
        finishTimeBegin: "",
        finishTimeEnd: "",
        rescueTimeBegin: "",
        rescueTimeEnd: "",
        rescueType: "",
        keyword: "",
        tech: "",
        kefu: "",
        saleman: "",
        keytype: "",
        settle:'',
        hesuan:'',
      },
      kefu: "",
      kefulist: [],
      saleman: "",
      saleslist: [],
      tech: "",
      techlist: [],
      isOp: true,
      timer: null,
      isChange: true,
      isShow: false,
      sousuo: [
        {
          value: "1",
          label: "订单号",
        },
        {
          value: "2",
          label: "车主信息",
        },
        {
          value: "3",
          label: "技师信息",
        },
        {
          value: "4",
          label: "任务点",
        },
        {
          value: "5",
          label: "目的地",
        },
        {
          value: "6",
          label: "合作商",
        },
        {
          value: "7",
          label: "案件号",
        },
      ],

      xiupart: false,
      canExport: false,
      xuanlist: [],
      tulist: [
        {
          value: 300,
          name: "现金金额",
        },
        {
          value: 800,
          name: "挂账金额",
        },
        {
          value: 900,
          name: "流转金额",
        },
      ],
      tulist2: [
        {
          value: 90,
          name: "现金单量",
        },
        {
          value: 80,
          name: "挂账单量",
        },
        {
          value: 70,
          name: "流转单量",
        },
      ],
      yingshou:'',
      pientryids:[],
      settletype:"",
      hesuantype:"",
      hasOp:'',
      isSale:false
    };
  },
  async created() {

    let res = await api.getOrderListOp();
    this.heZuoOptions = res.data.splist;
    this.isShow = res.data.isShow;
    this.isOp = res.data.isMain;
    this.isSale = res.data.isSale;

    let res1 = await index.gettechlist();
    this.techlist = res1.data;
    this.canExport = res1.financeExport;
    this.hasOp = res1.hasOp;

    let res2 = await index.getsaleslista();
    this.saleslist = res2.data;

    let res3 = await index.getkefulist();
    this.kefulist = res3.data;

    let { cid,st,et,type } = this.$route.query;
    //console.log("合作商过来的路由传参：", cid);
    if (cid != undefined && cid != "") {
      this.srccid = cid;
      this.lookfor();
    }else if(st != undefined && st != "" && et != undefined && et != "" && type != undefined && type != ''){
      if(type == 1)
      {
          this.value2 = [st,et];
          this.srccid = -999;
          this.lookfor();
      }else{
        this.value2 = [st,et];
        this.tech = -1;  
        this.lookfor();
      }
    } else {
      this.getlist(this.datas);
    }
  },
  methods: {
    initCharts() {
      let myChart = echarts.init(this.$refs.chart);
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        color:['#4076ff','#29cc8c','#FF950A'],
        series: [
          {
            type: "pie",
            radius: "100%",
            center: ["50%", "50%"],
            selectedMode: "single",
            label: {
              normal: {
                show: false,
                position: "center",
              },
            },
            data: this.tulist,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
    initCharts2() {
      let myChart = echarts.init(this.$refs.chart2);
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        color:['#4076ff','#29cc8c','#FF950A'],
        series: [
          {
            type: "pie",
            radius: "100%",
            center: ["50%", "50%"],
            selectedMode: "single",
            label: {
              normal: {
                show: false,
                position: "center",
              },
            },
            data: this.tulist2,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
    guanpientyr() {
      this.pizhangyin = false;
    },

    guanpientyr2() {
      this.pizhangyin = false;
      this.sx();
      this.xuanlist = [];
    },

    pizhang() {
      if (this.xuanlist.length !== 0) {
        let ids = [];
        for(let i = 0;i < this.xuanlist.length; i++)
        {
          if(this.xuanlist[i].issettle == 0)
          {
              ids.push(this.xuanlist[i].id)
          }

        }
        if(ids.length  == 0)
        {
            this.$message.warning("所选订单全已入账，请重新选择");
            return false;
        }
        this.pizhangyin = true;
        this.pientryids = ids;
      } else {
        this.$message.warning("请选择批量入账内容");
      }
    },
    piliang() {
      if (this.xuanlist.length !== 0) {
        ElMessageBox.confirm("确认批量核销吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let ids = [];
            for(let i = 0;i < this.xuanlist.length; i++)
            {
              if(this.xuanlist[i].ishesuan == 0)
              {
                  ids.push(this.xuanlist[i].id)
              }

            }
            if(ids.length == 0)
            {
              this.$message.warning('所选订单全已核销，请重新选择');
              return false;
            }
            let res = await api.hesuanOrderall(ids);

            if(res.code == 1)
            {
              this.$message.success(res.message);
              this.sx();
            }else{
              this.$message.warning(res.message);
            }

          })
          .catch(() => {
            this.$message.info("取消核销");
          });
      } else {
        this.$message.warning("请选择批量核销内容");
      }
    },
    pizhangall() {
      let data = {
            srccid: this.srccid,
            createTimeBegin:
              this.value3 == null || this.value3 == "" ? "" : this.value3[0],
            createTimeEnd:
              this.value3 == null || this.value3 == "" ? "" : this.value3[1],
            finishTimeBegin:
              this.value2 == null || this.value2 == "" ? "" : this.value2[0],
            finishTimeEnd:
              this.value2 == null || this.value2 == "" ? "" : this.value2[1],
            rescueTimeBegin:
              this.value1 == null || this.value1 == "" ? "" : this.value1[0],
            rescueTimeEnd:
              this.value1 == null || this.value1 == "" ? "" : this.value1[1],
            saleman: this.saleman,
          };

          this.datas = data;

          let ct = -1;
          let at = -1;
          let rt = -1;

          if(this.datas.srccid == '' && this.datas.saleman == '')
          {
            this.$message.warning("请选择具体业务员或合作单位进行全部核算");
            return false;            
          }

          if (
            Object.keys(this.datas).length == 0 ||
            (this.datas.createTimeBegin == "" &&
              this.datas.createTimeEnd == "" &&
              this.datas.finishTimeBegin == "" &&
              this.datas.finishTimeEnd == "" &&
              this.datas.rescueTimeBegin == "" &&
              this.datas.rescueTimeEnd == "")
          ) {
            this.$message.warning("请选择具体的日期");
            return false;
          }

          if (this.datas.createTimeBegin != "" && this.datas.createTimeEnd != "") {
            ct =
              (Date.parse(this.datas.createTimeEnd) -
                Date.parse(this.datas.createTimeBegin)) /
              86400000;
          }

          if (this.datas.finishTimeBegin != "" && this.datas.finishTimeEnd != "") {
            at =
              (Date.parse(this.datas.finishTimeEnd) -
                Date.parse(this.datas.finishTimeBegin)) /
              86400000;
          }

          if (this.datas.rescueTimeBegin != "" && this.datas.rescueTimeEnd != "") {
            rt =
              (Date.parse(this.datas.rescueTimeEnd) -
                Date.parse(this.datas.rescueTimeBegin)) /
              86400000;
          }



          if (ct > 31) {
            this.$message.warning("选择的创建时间不能超出1个月，请重新选择");
            return false;
          }

          if (at > 31) {
            this.$message.warning("选择的完成时间不能超出1个月，请重新选择");
            return false;
          }

          if (rt > 31) {
            this.$message.warning("选择的救援时间不能超出1个月，请重新选择");
            return false;
          }

          ElMessageBox.confirm("确认全部入账吗", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(async()  => {
            let res = await api.dosettleall(this.datas);
            if(res.code == 1)
            {
              this.$message.success("操作成功");
              this.sx();
            }else{
              this.$message.warning(res.message);
            }
            
          })
          .catch(() => {
            this.$message.info("取消操作");
          });
    },

    piliangall() {
          let data = {
                srccid: this.srccid,
                createTimeBegin:
                  this.value3 == null || this.value3 == "" ? "" : this.value3[0],
                createTimeEnd:
                  this.value3 == null || this.value3 == "" ? "" : this.value3[1],
                finishTimeBegin:
                  this.value2 == null || this.value2 == "" ? "" : this.value2[0],
                finishTimeEnd:
                  this.value2 == null || this.value2 == "" ? "" : this.value2[1],
                rescueTimeBegin:
                  this.value1 == null || this.value1 == "" ? "" : this.value1[0],
                rescueTimeEnd:
                  this.value1 == null || this.value1 == "" ? "" : this.value1[1],
                saleman: this.saleman,
              };

          this.datas = data;

          let ct = -1;
          let at = -1;
          let rt = -1;

          if(this.datas.srccid == '' && this.datas.saleman == '')
          {
            this.$message.warning("请选择具体业务员或合作单位进行全部核算");
            return false;            
          }

          if (
            Object.keys(this.datas).length == 0 ||
            (this.datas.createTimeBegin == "" &&
              this.datas.createTimeEnd == "" &&
              this.datas.finishTimeBegin == "" &&
              this.datas.finishTimeEnd == "" &&
              this.datas.rescueTimeBegin == "" &&
              this.datas.rescueTimeEnd == "")
          ) {
            this.$message.warning("请选择具体的日期");
            return false;
          }

          if (this.datas.createTimeBegin != "" && this.datas.createTimeEnd != "") {
            ct =
              (Date.parse(this.datas.createTimeEnd) -
                Date.parse(this.datas.createTimeBegin)) /
              86400000;
          }

          if (this.datas.finishTimeBegin != "" && this.datas.finishTimeEnd != "") {
            at =
              (Date.parse(this.datas.finishTimeEnd) -
                Date.parse(this.datas.finishTimeBegin)) /
              86400000;
          }

          if (this.datas.rescueTimeBegin != "" && this.datas.rescueTimeEnd != "") {
            rt =
              (Date.parse(this.datas.rescueTimeEnd) -
                Date.parse(this.datas.rescueTimeBegin)) /
              86400000;
          }



          if (ct > 31) {
            this.$message.warning("选择的创建时间不能超出1个月，请重新选择");
            return false;
          }

          if (at > 31) {
            this.$message.warning("选择的完成时间不能超出1个月，请重新选择");
            return false;
          }

          if (rt > 31) {
            this.$message.warning("选择的救援时间不能超出1个月，请重新选择");
            return false;
          }

          ElMessageBox.confirm("确认全部入核算", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(async()  => {
            let res = await api.dohesuanall(this.datas);
            if(res.code == 1)
            {
              this.$message.success("操作成功");
              this.sx();
            }else{
              this.$message.warning(res.message);
            }
            
          })
          .catch(() => {
            this.$message.info("取消操作");
          });
    },
    handleSelectionChange(val) {
      //console.log("选中了", val);
      this.xuanlist = val;
    },
    gd(type)
    {
      this.datas.chargeType = type;
      this.chargetype = type.toString();
      this.sx();

    },
    dd(id) {
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
      this.detailid = id;
    },
    xiu(id) {
      this.xiupart = true;
      this.detailid = id;
    },
    closepart() {
      this.xiupart = false;
      this.sx();
    },
    guanentyr1() {
      this.zhangyin = false;
    },
    guanentyr2() {
      this.zhangyin = false;
      this.sx();
      this.xuanlist = [];
    },
    async accounting(id) {
      ElMessageBox.confirm("确认核销吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async()  => {
          let res = await api.hesuanOrder(id);
          if(res.code == 1)
          {
            this.$message.success("核销成功");
            this.sx();
          }else{
            this.$message.warning(res.message);
          }
          
        })
        .catch(() => {
          this.$message.info("取消核销");
        });
    },
    settle(row) {
      this.zhangyin = true;
      this.entryid = row.id;
      this.yingshou = row.yingshou;
      // ElMessageBox.confirm("确认入账吗", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(async () => {
      //     let xiures = await api.settle(id);
      //     console.log("修改数据---", xiures);
      //     this.$message.success("入账成功");
      //     this.sx();
      //   })
      //   .catch(() => {
      //     this.$message.info("取消入账");
      //   });
    },
    async ti() {
      let res = await index.getTixian();

      // console.log("---------------", res);

      if (res.code == 1) {
        this.xian++;
        if (this.xian == 3) {
          this.xian = 1;
        }
      } else {
        this.$message.warning(res.message);
      }
    },
    search3() {
      this.datas.keyword = this.keyword;
      this.getlist(this.datas);
    },
    async getlist(datas) {
      let res = await api.getFinancelistNew(datas);
      //console.log(res);
      this.list = res.data == null?[]:res.data;
      this.total = parseInt(res.total);
      this.companyinfo = res.companyinfo;

      this.tulist[0].value = res.companyinfo.xmoney;
      this.tulist[1].value = res.companyinfo.gmoney;
      this.tulist[2].value = res.companyinfo.pmoney;
      this.tulist2[0].value = res.companyinfo.xnum;
      this.tulist2[1].value = res.companyinfo.gnum;
      this.tulist2[2].value = res.companyinfo.pnum;
      this.initCharts();
      this.initCharts2()
    },
    sx() {
      this.datas.page = 1;
      this.getlist(this.datas);
    },
    sxx() {
      this.getlist(this.datas);
    },
    export1() {
      let data = {
        page: 1,
        perpage: 15,
        srccid: this.srccid,
        chargeType: this.chargetype,
        createTimeBegin:
          this.value3 == null || this.value3 == "" ? "" : this.value3[0],
        createTimeEnd:
          this.value3 == null || this.value3 == "" ? "" : this.value3[1],
        finishTimeBegin:
          this.value2 == null || this.value2 == "" ? "" : this.value2[0],
        finishTimeEnd:
          this.value2 == null || this.value2 == "" ? "" : this.value2[1],
        rescueTimeBegin:
          this.value1 == null || this.value1 == "" ? "" : this.value1[0],
        rescueTimeEnd:
          this.value1 == null || this.value1 == "" ? "" : this.value1[1],
        rescueType: this.group,
        keyword: this.keyword,
        tech: this.tech,
        kefu: this.kefu,
        saleman: this.saleman,
        keytype: this.keytype,
        hesuan:this.hesuantype,
        settle:this.settletype
      };
      this.datas = data;

      let ct = -1;
      let at = -1;
      let rt = -1;

      if (
        Object.keys(this.datas).length == 0 ||
        (this.datas.createTimeBegin == "" &&
          this.datas.createTimeEnd == "" &&
          this.datas.finishTimeBegin == "" &&
          this.datas.finishTimeEnd == "" &&
          this.datas.rescueTimeBegin == "" &&
          this.datas.rescueTimeEnd == "")
      ) {
        this.$message.warning("请选择具体的导出日期");
        return false;
      }

      if (this.datas.createTimeBegin != "" && this.datas.createTimeEnd != "") {
        ct =
          (Date.parse(this.datas.createTimeEnd) -
            Date.parse(this.datas.createTimeBegin)) /
          86400000;
      }

      if (this.datas.finishTimeBegin != "" && this.datas.finishTimeEnd != "") {
        at =
          (Date.parse(this.datas.finishTimeEnd) -
            Date.parse(this.datas.finishTimeBegin)) /
          86400000;
      }

      if (this.datas.rescueTimeBegin != "" && this.datas.rescueTimeEnd != "") {
        rt =
          (Date.parse(this.datas.rescueTimeEnd) -
            Date.parse(this.datas.rescueTimeBegin)) /
          86400000;
      }

      if (ct > 31) {
        this.$message.warning("创建时间导出不能超出1个月，请重新选择");
        return false;
      }

      if (at > 31) {
        this.$message.warning("完成时间导出不能超出1个月，请重新选择");
        return false;
      }

      if (rt > 31) {
        this.$message.warning("救援时间导出不能超出1个月，请重新选择");
        return false;
      }

      this.$msgbox({
        title: "消息",
        message: "是否确定导出",
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "导出中...";
            setTimeout(async () => {
              let res = await api.getFinancelistExportNew(this.datas);
              console.log("打印结果", res);
              done();
              if (res.status == 200) {
                instance.confirmButtonLoading = false;
              }
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success("导出成功请保存");
        })
        .catch(() => {
          this.$message.warning("取消导出");
        });
    },
    lookfor() {

      if(this.keytype == '' && this.keyword != '')
      {
          this.$message.warning('请选择搜索类型，进行关键词搜索');
          return false;
      }
      
      let data = {
        page: 1,
        perpage: 15,
        srccid: this.srccid,
        chargeType: this.chargetype,
        createTimeBegin:
          this.value3 == null || this.value3 == "" ? "" : this.value3[0],
        createTimeEnd:
          this.value3 == null || this.value3 == "" ? "" : this.value3[1],
        finishTimeBegin:
          this.value2 == null || this.value2 == "" ? "" : this.value2[0],
        finishTimeEnd:
          this.value2 == null || this.value2 == "" ? "" : this.value2[1],
        rescueTimeBegin:
          this.value1 == null || this.value1 == "" ? "" : this.value1[0],
        rescueTimeEnd:
          this.value1 == null || this.value1 == "" ? "" : this.value1[1],
        rescueType: this.group,
        keyword: this.keyword,
        tech: this.tech,
        kefu: this.kefu,
        saleman: this.saleman,
        keytype: this.keytype,
        hesuan:this.hesuantype,
        settle:this.settletype,
      };
      this.datas = data;

      //console.log(this.datas);
      this.getlist(this.datas);
    },
    renew() {
      this.datas = {
        page: 1,
        perpage: 15,
        srccid: "",
        chargeType: "",
        createTimeBegin: "",
        createTimeEnd: "",
        finishTimeBegin: "",
        finishTimeEnd: "",
        rescueTimeBegin: "",
        rescueTimeEnd: "",
        rescueType: "",
        keyword: "",
        tech: "",
        kefu: "",
        saleman: "",
        keytype: "",
        hesuan:'',
        settle:'',
      };
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.keytype = "";
      this.keyword = "";
      this.rescueType = "";
      this.srccid = "";
      this.chargetype = "";
      this.saleman = "";
      this.kefu = "";
      this.tech = "";
      this.hesuantype ="";
      this.settletype ="";
      this.getlist(this.datas);
    },
    prev_view() {
      console.log("---订单上一页", this.datas.page);
    },
    // 分页器
    next_view() {
      console.log("---订单下一页", this.datas.page);
    },
    // 点击页数时触发
    current_view(e) {
      this.datas.page = e;
      this.getlist(this.datas);
    },
  },
  computed: {
    getismain() {
      return this.$store.state.ismain;
    },
    getlook()
    {
      return this.$store.state.lookonly;
    }
  },
  watch: {},

  mounted() {
    this.initCharts();
    this.initCharts2();
    let wealthDiv = document.querySelector(".el-scrollbar__wrap");
    wealthDiv.addEventListener(
      "scroll",
      () => {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          let x = 10;
          if (
            wealthDiv.offsetHeight + wealthDiv.scrollTop >=
            wealthDiv.scrollHeight - x
          ) {
            if (this.isChange && this.list.length < this.total) {
              this.isChange = false;
              this.datas.page++;
              let res = await api.getFinancelistNew(this.datas);
              this.list = [...this.list, ...res.data];
              this.isChange = true;
              // console.log("这里触发了");
            } else {
              this.$message.warning("没有更多了");
            }
          }
        }, 500);
      },
      true
    );
  },
};
</script>

<style>
.el-checkbox-button:first-child .el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
}
.el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
}

.el-popover.el-popper{
  min-width: 0 !important;
  min-height: 0 !important;
}
</style>

<style lang="less" scoped>
.elxuan {
  float: left;
}

.left {
  float: left;
  width: 280px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: relative;
}
.jian {
  float: left;
  transform: rotate(90deg);
  margin-right: 17px;
  margin-top: 10px;
}
.xian {
  float: left;
  width: 0px;
  height: 14px;
  margin-top: 7px;
  margin-right: 14px;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.sub {
  float: left;
  width: 250px;
  height: 30px;
  border: 0;
  outline: none;
  position: absolute;
  padding-left: 12px;
  box-sizing: border-box;
}
.jing {
  float: left;
  width: 30px;
  height: 30px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
}
.icon1 {
  // width: 14px;
  height: 14px;
  margin-left: 9px;
  margin-top: 8px;
  //   border: 2px solrgb(27, 28, 29)e96;
  border-radius: 50%;
  opacity: 1;
}
.icon-icon1:before {
  float: left;
  margin-top: 8px;
  margin-right: 10px;
}
.dingHao {
  float: left;
  // width: 42px;
  // height: 19px;
  font-size: 14px;
  margin-top: 16px;
  margin-left: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  margin-right: 16px;
  color: #2a3346;
  opacity: 1;
}

.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color-base);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.box {
  width: 1730px;
  height: 840px;
  margin: 0;
  margin-left: 190px;
  margin-top: 0px;
  padding: 30px;
  box-sizing: border-box;
  background-color: #f8fafc;
}
.nav-box {
  width: 1670px;
  // height: 160px;
  margin-bottom: 15px;
  background-color: #fff;
  // margin-top: 30px;
  padding: 15px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main-box {
  width: 1670px;
  height: 450px;
  margin-top: 20px;
  background-color: #fff;
}
.option {
  // float: left;
  width: 100%;
  // height: 100px;
  box-sizing: border-box;
  // margin-top: -33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.import {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.screen {
  height: 32px;
  float: right;
  // margin-top: -31px;
  display: flex;
}
.left-screen {
  float: left;
  // width: 100px;
  height: 40px;
  // border: 1px solid #2c68ff;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.screen-img {
  // float: left;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  // margin-left: 27px;
  // margin-top: 12px;
  box-sizing: border-box;
}
.screen-p {
  float: left;
  // width: 32px;
  // height: 22px;
  font-size: 16px;
  // margin-top: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  // line-height: 0px;
  color: #2c68ff;
  opacity: 1;
}

.option-label {
  float: left;
  line-height: 100px;
  margin-left: 30px;
  margin-right: 30px;
}
.mt-4 {
  display: flex;
  box-sizing: border-box;
  align-items: center;
}

.el-checkbox-button {
  margin-right: 10px;
  // margin-bottom: 12px;
  // height: 34px;
  background: #f7f9fb;
  // border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
}

.btn {
  height: 32px;
  opacity: 1;
}
.set {
  width: 80px;
  height: 40px;
  background: #2c68ff;
  opacity: 1;
  color: #fff;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
}
.cancel {
  width: 80px;
  height: 40px;
  background: #ffffff;
  margin-left: 20px;
  border: 1px solid #727e96;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
}

.newhead {
  width: 1670px;
  // height: 120px;
  // background-color: pink;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

ul {
  list-style: none;
}
.headbox,
.headbox4 {
  float: left;
  // height: 196px;
  padding: 15px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(179, 189, 195, 0.16);
  opacity: 1;
  border-radius: 8px;
}
.headbox {
  width: 382px;
}
.headbox4 {
  width: 480px;
}
.fianlly {
  margin-right: 0;
}
.li-img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.navsp {
  display: inline-block;
  // width: 80px;
  height: 21px;
  // font-size: 16px;
  margin-top: 38px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  color: #727e96;
  opacity: 1;
}
.balance {
  padding-left: 33px;
}
.el-input {
  width: 200px;
  border: 0;
}

.first {
  width: 390px;
  background-color: #e9efff;
  position: relative;
}
.deposit {
  width: 90px;
  height: 32px;
  color: #fff;
  font-size: 16px;
  background: #2c68ff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 0;
  position: absolute;
  right: 36px;
  bottom: 29px;
  cursor: pointer;
}
// <!--内页-->
i {
  font-style: normal;
  color: #2c68ff;
}

.zhong /deep/ .el-select {
  width: 150px;
  height: 32px !important;
  margin-right: 20px;
}
.jishi /deep/ .el-select {
  width: 120px;
  height: 32px !important;
  margin-right: 20px;
}
.left-screen /deep/ .el-select {
  width: 120px;
  height: 32px !important;
  margin-left: 10px;
}

.mt-4 /deep/ .el-checkbox-button--large .el-checkbox-button__inner {
  padding: 8px 10px;
  box-sizing: border-box;
}

.wealthlist {
  height: 400px;
  position: relative;
}

.wealthlist /deep/ .el-table-fixed-column--right:last-of-type {
  width: 230px;
  display: flex;
  align-items: center;
  height: 70px;
}

.wealthlist /deep/ .el-table th.el-table__cell.is-leaf {
  background-color: #f5f5f5;
  color: #000;
  height: 50px;
}
.wealthlist /deep/ .el-table__row {
  height: 70px !important;
  box-sizing: border-box;
}

.wealthlist /deep/ .el-table__cell {
  padding: 0;
}
.wealthlist /deep/ .el-button--small {
  width: 60px;
}
.allNum {
  font-size: 14px;
  text-align: right;
  color: #2c68ff;
  height: 40px;
  line-height: 40px;
}

.seek {
  float: left;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 156px;
  height: 32px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  border-left: 0;
}
.seek /deep/ .el-select .el-input__inner {
  width: 110px;
  border-radius: 0;
}
.chaxun {
  width: 80px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c68ff;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.export {
  display: flex;
  width: 60px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border: 1px solid #727e96;
  box-sizing: border-box;
  margin-left: 5px;
  cursor: pointer;
}
.seek /deep/ .el-select {
  width: 100px !important;
  margin: 0 !important;
}
.importleft {
  display: flex;
  align-items: center;
}
.importleft /deep/ .el-select {
  width: 250px !important;
  margin: 0 !important;
  margin-right: 10px !important;
}
.importleft /deep/ .el-range-editor.el-input__inner {
  width: 220px;
  margin: 0 !important;
}
.importleft /deep/ .el-form-item {
  margin: 0 !important;
  margin-right: 20px !important;
}
.xiangqing {
  font-style: normal;
  color: #2c68ff;
  cursor: pointer;
}

.headboxnav {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-bottom: 20px;
}
.headboxnavtit {
  font-size: 14px;
  color: #44444f;
}
.headboxnavtxt {
  font-size: 14px;
  color: #92929d;
}
.headboxmain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headboxright {
  width: 100px;
  margin-top: 15px;
}
.el-progress-circle {
  width: 100px;
  height: 100px;
}
.priceall {
  color: #171746;
  font-size: 24px;
  font-weight: bold;
  // margin-bottom: 4px;
}
.pricetit {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #92929d;
  margin-top: 10px;
  cursor: pointer;
}
.pricenum {
  color: #44444f;
  font-weight: 500;
}
.dian,
.dian2,
.dian3 {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
}
.dian {
  background-color: #FF950A;
}
.dian2 {
  background-color: #29cc8c;
}
.dian3 {
  background-color: #4076ff;
}
.tiao {
  width: 100%;
  height: 8px;
  background-color: #4076ff;
  border-radius: 4px;
  margin-top: 10px;
}
.tiao2 {
  width: 100%;
  height: 8px;
  background-color: #ff950a;
  border-radius: 4px;
  margin-top: 12px;
  float: right;
}

.tiao5{
  width: 100%;
  height: 8px;
  margin-top: 12px;
  float: right;
}
.tiao3 {
  width: 50%;
  height: 8px;
  background-color: #29CC8C;
  border-radius: 4px 0px 0px 4px;
  float:left;

}
.tiao4 {
  width: 50%;
  height: 8px;
  background-color: #ff950a;
  border-radius: 0px 4px 4px 0px;
  float:right;

}
.yingshou {
  margin-right: 20px;
}
.yingfu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0;
}
.yingfu1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0;
}
.yingtit {
  font-size: 14px;
  color: #44444f;
  margin-top: 8px;
}
.tixian,
.Recharge {
  width: 60px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #2c68ff;
  font-size: 14px;
  cursor: pointer;
}
.tixian {
  background-color: #edf2ff;
  color: #2c68ff;
}
.Recharge {
  background-color: #2c68ff;
  color: #fff;
  margin-left: 12px;
}
.headall {
  margin-bottom: 8px;
}
.newbalance {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #92929d;
  margin-top: 17px;
}
.newbalancenum {
  color: #44444f;
}
// .yingall{
//   margin-bottom: 4px;
// }
.chatit {
  color: #29cc8c;
}
.chatitRed {
  color:red;
}
.bingtu {
  border: 0;
}

.all {
  width: 60px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #2c68ff;
  font-size: 14px;
  cursor: pointer;
  background-color: #edf2ff;
  color: #2c68ff;
  margin-right: 10px;
}

.zhangtit {
  font-size: 20px;
}
</style>

